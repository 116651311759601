import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import FileUploader from "../../../components/FileUploader";
import SellModal from "../../../components/SellModal/SellModal";
import { PictureProps } from "../../../interfaces/pages";
import styles from "./pictures.module.scss";
import { trackEvent } from "../../../config/firebase";


const Pictures = ({
    postPublicationsImages,
    deletePublicationImage,
    fetchingDeleteImage,
    cleanDeleteImage,
    imagesVideos,
    setImages,
    plate,
    isFoundPlate
}: PictureProps) => {
    const navigate = useNavigate();
    const [disabledButton, setDisabledButton] = useState<boolean>(false);

    const onChangeFileUploader = (files: any) => {
        setDisabledButton(files && files.filter((item: any) => !item?.toDelete).length < 3);
        setImages(files);
        files && files.forEach(async (file: any) => {
            if (file.toDelete) {
                deletePublicationImage(file?.id);
                return;
            }
            if (file?.previewURL) {
                let formData = new FormData();
                formData.append("file", file?.file, file?.name);
                postPublicationsImages(formData, file?.id);
            }
        })
    }

    const onPressNext = () => {
        navigate("/sell/value_and_payment");
    };

    const disabled = useMemo(() => {
        let isDisabled = false;
        const foundedOne = imagesVideos && imagesVideos.find((image: any) => image?.upload === "loading" || image?.upload === "failure");
        if (foundedOne || imagesVideos.length < 3) {
            isDisabled = true;
        }
        return isDisabled;
    }, [imagesVideos]);

    useEffect(() => {
        if (fetchingDeleteImage === "failure") {
            cleanDeleteImage();
        }
        if (fetchingDeleteImage === "success") {
            cleanDeleteImage();
        }
    }, [fetchingDeleteImage]);

    useEffect(() => {
        if (!plate) return navigate("/sell");
        trackEvent("venta-paso-imagenes")
    }, []);

    return (
        <SellModal
            step={isFoundPlate ? 6 : 8}
            title="Añadir fotos"
            subTitle="Debes añadir mínimo 3 fotos"
            onClickBack={() => navigate("/sell/description")}
            isFoundPlate={isFoundPlate}
        >
            <p className={styles.text}>Tamaño máximo por foto: 10mb</p>
            <div className={`${styles.inputsContainer} ${imagesVideos.length === 0 && styles.noImages}`}>
                <FileUploader
                    values={imagesVideos}
                    onChange={onChangeFileUploader}
                    className={styles.fileUploader}
                    scroll={imagesVideos.length > 0}
                />
            </div>
            <Button
                onPress={onPressNext}
                title="Continuar"
                primary
                disabled={disabled || disabledButton}
                className={styles.submitButton}
            />
        </SellModal>
    )
};

export default Pictures;