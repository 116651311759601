
import { useMemo, useEffect, useState } from "react";
import styles from "./valueAndPayment.module.scss";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { ValueAndPaymentProps} from "../../../interfaces/salePost";
import { optionsPayd, optionsNegotiate} from "../../../constants/salePost";
import { addPointsInText, removePointsInText } from "../../../helpers/app";
import Button from "../../../components/Button";
import SellModal from "../../../components/SellModal/SellModal";
import SuggestedPrices from "../../../components/SuggestedPrices";
import ToggleButtonShared from "../../../components/ToggleButton/ToggleButton";
import TextFieldShared from "../../../components/TextField/TextField";
import { YesNoTypes } from "../../../interfaces/types";
import { trackEvent } from "../../../config/firebase";

const ValueAndPayment = ({
    vehiclePaid,
    setIsPaid,
    negotiatePrice,
    setIsNegotiate,
    setSuggestedPrices,
    suggestedPrices,
    fetchingSuggedtedPrices,
    fetchingAdditionalCost,
    additionalCost,
    setPrice,
    price,
    user,
    setLoginRedirection,
    plate,
    getAdditionalExpensesSalePost,
    isFoundPlate
}: ValueAndPaymentProps) => {
    const navigate = useNavigate();
    const [prices, setPrices] = useState({
        retake: '0',
        publication: '0',
        sale: '0',
    });
    const [isNegotiatePrice, setIsNegotiatePrice] = useState<YesNoTypes>("SI");
    const [isVehiclePaid, setIsVehiclePaid] = useState<YesNoTypes>("SI");
    const [carPrice, setCarPrice] = useState<string>("0");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [notSuggestedPrice, setNotSuggestedPrice] = useState<boolean>(false);

    const handleChangeCarPrice = (value: string) => {
        if(parseInt(value) < parseInt(removePointsInText(prices.retake))){
            setErrorMessage("El precio es muy bajo");
        }else{
            if((parseInt(value) * 100) / parseInt(removePointsInText(prices.publication)) > 110){
                setErrorMessage("El precio es muy alto");
            }else{
                setErrorMessage("");
                getAdditionalExpensesSalePost(parseInt(carPrice));
            }
        }
        setCarPrice(value);
        setPrice(value);
    }

    const onPressNext = () => {
        setIsPaid(isVehiclePaid === "SI" ? true : false);
        setIsNegotiate(isNegotiatePrice === "SI" ? true : false);
        setPrice(carPrice.replaceAll(".", ""));
        if(notSuggestedPrice){
            setSuggestedPrices({
                retake: removePointsInText(carPrice.replaceAll(".", "")),
                publication: removePointsInText(carPrice.replaceAll(".", "")),
                sale: removePointsInText(carPrice.replaceAll(".", ""))
            });
        }
        navigate("/sell/detail");
    };

    useEffect(() => {
        setIsVehiclePaid(vehiclePaid ? "SI" : "NO");
    }, [vehiclePaid]);

    useEffect(() => {
        setPrices({
            retake: addPointsInText(suggestedPrices.retake),
            publication: addPointsInText(suggestedPrices.publication),
            sale: addPointsInText(suggestedPrices.sale)
        });
        if(price === "0" && fetchingSuggedtedPrices === "success"){
            setCarPrice(suggestedPrices.sale.toString());
        }else{
            setCarPrice(price);
        }
    }, [suggestedPrices]);
    
    useEffect(() => {
        if(fetchingSuggedtedPrices === "failure"){
            setNotSuggestedPrice(true);
        }
    }, [fetchingSuggedtedPrices]);

    useEffect(() => {
        setIsNegotiatePrice(negotiatePrice ? "SI" : "NO");
    }, [negotiatePrice]);

    useEffect(() => {
        getAdditionalExpensesSalePost(removePointsInText(prices?.sale));
    }, [prices]);

    useEffect(() => {
        if(!plate) return navigate("/sell");
    }, [plate]);

    useEffect(() => {
        trackEvent("venta-paso-valor-vehículo")
    },[])

    return (
        <SellModal
            onClickBack={() => navigate("/sell/pictures")}
            step={isFoundPlate ? 7 : 9}
            title="Ingresa el valor de tu auto"
            isFoundPlate={isFoundPlate}
        >
            <div className={styles.inputsContainer}>
                <TextFieldShared
                    variant="borderless"
                    name="liquidRent"
                    value={carPrice}
                    onChange={handleChangeCarPrice}
                    className={styles.carPrice}
                    autoFocus={true}
                />
                {
                    !notSuggestedPrice &&
                    <SuggestedPrices lowPrice={prices.retake} mediumPrice={prices.sale} highPrice={prices.publication} />
                }
                {
                    !notSuggestedPrice && errorMessage && errorMessage.length > 0 &&
                    <p className={styles.errorMessage}>{errorMessage}</p>
                }
                <div className={styles.costs}>
                    <p className={styles.message}>*Valor referencial de mercado otorgado por Autored</p>
                    <div className={styles.horizontalLine}></div>
                    <p className={styles.title}>Gastos operacionales</p>
                    <p className={styles.item}>
                        <span className={styles.name}>Costos de transacción</span>
                        <span className={styles.value}>
                            {
                                fetchingAdditionalCost === "loading" 
                                ? <Skeleton animation="wave" variant="text" className={styles.skeleton} />
                                : `$${
                                    parseInt(removePointsInText(carPrice)) < (parseInt(additionalCost?.calculation_addcosts?.inspection) + parseInt(additionalCost?.calculation_addcosts?.seller_transaction_cost)) 
                                    ? 0 
                                    : addPointsInText(additionalCost?.calculation_addcosts?.seller_transaction_cost)
                                }`
                            }
                        </span>
                    </p>
                    <div className={styles.horizontalLine}></div>
                    <p className={styles.item}>
                        <span className={styles.name}>Inspección</span>
                        <span className={styles.value}>
                            {
                                fetchingAdditionalCost === "loading" 
                                ? <Skeleton animation="wave" variant="text" className={styles.skeleton} /> 
                                : `$${
                                    parseInt(removePointsInText(carPrice)) < (parseInt(additionalCost?.calculation_addcosts?.inspection) + parseInt(additionalCost?.calculation_addcosts?.seller_transaction_cost)) 
                                    ? 0 
                                    : additionalCost?.calculation_addcosts?.inspection
                                }`
                            }
                        </span>
                    </p>
                    <div className={styles.horizontalLine}></div>
                    <p className={`${styles.item} ${styles.totalAdministrativeCharges}`}>
                        <span className={`${styles.name} ${styles.bold}`}>Total gastos operacionales</span>
                        <span className={`${styles.value} ${styles.bold}`}>
                            {
                                fetchingAdditionalCost === "loading"
                                ? <Skeleton animation="wave" variant="text" className={styles.skeleton} /> 
                                : `$${
                                    parseInt(removePointsInText(carPrice)) < (parseInt(additionalCost?.calculation_addcosts?.inspection ?? 0) + parseInt(additionalCost?.calculation_addcosts?.seller_transaction_cost ?? 0)) 
                                    ? 0 
                                    : addPointsInText(parseInt(additionalCost?.calculation_addcosts?.inspection ?? 0) + parseInt(additionalCost?.calculation_addcosts?.seller_transaction_cost ?? 0))
                                }`
                            }
                        </span>
                    </p>
                    <div className={styles.horizontalLine}></div>
                    <p className={`${styles.title} ${styles.resume}`}>Resumen de venta</p>
                    <p className={`${styles.item} ${styles.totalDeducedCharges}`}>
                        <span className={styles.name}>Monto total a recibir</span>
                        <span className={`${styles.value} ${styles.bold}`}>
                            {
                                fetchingAdditionalCost === "loading"
                                ? <Skeleton animation="wave" variant="text" className={styles.skeleton} /> 
                                : `$${
                                    parseInt(removePointsInText(carPrice)) < (parseInt(additionalCost?.calculation_addcosts?.inspection ?? 0) + parseInt(additionalCost?.calculation_addcosts?.seller_transaction_cost ?? 0)) 
                                    ? 0 
                                    : addPointsInText(parseInt(removePointsInText(carPrice)) - (parseInt(additionalCost?.calculation_addcosts?.inspection ?? 0) + parseInt(additionalCost?.calculation_addcosts?.seller_transaction_cost ?? 0)))
                                }`
                            }
                        </span>
                    </p>
                </div>
                <div className={styles.horizontalLine}></div>
                <div className={styles.switch}>
                    <p className={styles.title}>¿Estás dispuesto a negociar el precio?</p>
                    <ToggleButtonShared
                        type="group"
                        value={isNegotiatePrice}
                        onPress={(value: YesNoTypes) => setIsNegotiatePrice(value)}
                        exclusive
                        size="small"
                    >
                        {optionsNegotiate}
                    </ToggleButtonShared>
                </div>
                <div className={styles.switch}>
                    <p className={styles.title}>¿Terminaste de pagar tu vehículo?</p>
                    <ToggleButtonShared
                        type="group"
                        value={isVehiclePaid}
                        onPress={(value: YesNoTypes) => setIsVehiclePaid(value)}
                        exclusive
                        size="small"
                    >
                        {optionsPayd}
                    </ToggleButtonShared>
                </div>
            </div>
            <Button 
                onPress={onPressNext}
                title="Continuar" 
                primary 
                disabled={fetchingAdditionalCost === "loading" || carPrice === "0" || (parseInt(removePointsInText(carPrice)) < (parseInt(additionalCost?.calculation_addcosts?.inspection) + parseInt(additionalCost?.calculation_addcosts?.seller_transaction_cost)))}
                className={styles.submitButton}
            />
        </SellModal>
    )
};

export default ValueAndPayment;