import styles from "./footer.module.scss";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { FooterProps } from "../../interfaces/components";
import { useWindowSize } from "use-hooks";
import { images } from "../../constants/images";
import { AnalyticsService } from "../../helpers/analytics";
import { trackEvent } from "../../config/firebase";

const Footer = ({
    user,
    searchType,
    setTypeReducer
}: FooterProps) => {
    const location = useLocation();
    const windowSize = useWindowSize();
    const navigate = useNavigate();

    const handlePressFilter = (value: string) => {
        if(value === ""){
            setTypeReducer([]);
            return;
        }
        if(value === 'van' || value === 'deportivo') setTypeReducer([]);
        else setTypeReducer([value]);
    }

    return (
        <footer className={styles.footerContainer}>
            <section className={styles.footerContent}>
                <div className={styles.leftFooter}>
                    <img src={images.isoLogoTipo} />
                    <p>Es un producto de Linzecar SPA®.</p>
                </div>
                <div className={styles.rightFooter}>
                    <div className={styles.rowFooter}>
                        {/* <NavLink to='/what-is-linze' className={styles.aLink}>
                            ¿Qué es Linze?
                        </NavLink> */}
                        <NavLink to='/search' onClick={() => AnalyticsService.trackButtonClick('buy', 'footer')} className={styles.aLink}>
                            Comprar
                        </NavLink>
                        <NavLink to={'/sell'} onClick={() => trackEvent("click-vender")} className={styles.aLink}>
                            Vender
                        </NavLink>
                        {/* <NavLink>
                            Blog
                        </NavLink> */}
                        <NavLink to={'/login'} className={styles.aLink}>
                            Ingresar
                        </NavLink>
                        <div className={styles.socialNetworksContainer}>
                            <img onClick={() => window.open("https://www.instagram.com/linzecar.chile?igsh=MWZtejMwZjlobjFsaQ==","_blank")} src={images.igIconFooter} />
                            <img onClick={() => window.open("https://www.facebook.com/Linzecar.chile/","_blank")} src={images.fbIconFooter} />
                            <img onClick={() => window.open("https://www.linkedin.com/company/linze-car/about/","_blank")} src={images.linkedinIconFooter} />
                        </div>
                    </div>
                    <div className={styles.rowFooter}>
                        <p>Copyright © 2023 linze. Todos los derechos reservados</p>
                        <p>Visita nuestros <span onClick={() => navigate("/terms")}>términos y condiciones</span></p>
                    </div>
                </div>
            </section>
        </footer>
    )
}
export default Footer;